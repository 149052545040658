<template>
	<el-dialog
		v-model="visible"
		width="800px"
		class="container"
		title="更新账单状态"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				已选中
				<span style="color: red">{{ model.length }}</span>
				笔账单，合并金额{{ amount }}，账单明细如下：
			</p>
			<el-form :model="formData" :rules="rules" ref="tableRef">
				<el-table border ref="yiTableRef" height="100%" :columns="columns" :data="formData.model">
					<el-table-column prop="platformChannel" label="平台渠道" width="120px"></el-table-column>
					<el-table-column prop="month" label="账单月份" width="120px">
						<template #default="{ row }">
							<p>{{ convertDate(row) }}</p>
						</template>
					</el-table-column>
					<el-table-column prop="tradeBillType" label="账单类型" width="100px">
						<template #default="{ row }">
							<span>{{ mapDict('trade_bill_type', row.tradeBillType) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="completionDate" label="完成支付时间" width="180px">
						<template #default="{ row }">
							<el-date-picker
								:clearable="false"
								style="width: 150px"
								v-model="row.completionDate"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="tradeAmount" label="总应收额" width="100px"></el-table-column>
					<el-table-column prop="actualAmount" label="总实收额" width="140px">
						<template #default="{ row, $index }">
							<el-form-item
								width="120"
								:prop="`model.${$index}.actualAmount`"
								:rules="rules.actualAmount"
							>
								<el-input
									style="margin-top: 20px"
									v-model="row.actualAmount"
									placeholder="请输入总实收额"
								/>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="carNumber" label="车牌号" width="120px"></el-table-column>
					<el-table-column prop="driverName" label="司机姓名" width="100px"></el-table-column>
				</el-table>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import numeral from 'numeral'
import { defineExpose, inject, defineEmits, ref } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { updateBill } from '@/api/driver.js'

const $message = inject('$message')
const tableRef = ref(null)
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState([])
const [formData, setForm] = useState({ model: model.value })
const [amount, setAmount] = useState(0)
const [dicts, setDict] = useState({})
const { isLoading, fetchData } = useFetch()
const rules = {
	actualAmount: [
		{ required: true, message: '总实收额不能为空', trigger: 'blur' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '总实收额格式有误', trigger: 'blur' },
	],
}

const columns = [
	{
		prop: 'platformChannel',
		label: '平台渠道',
		width: '120px',
	},
	{
		prop: 'tradeBillType',
		label: '账单类型',
		width: '120px',
	},
	{
		prop: 'month',
		label: '账单月份',
		width: '120px',
	},
	{
		prop: 'tradeAmount',
		label: '总应收额',
		width: '120px',
	},
	{
		prop: 'actualAmount',
		label: '总实收额',
		width: '120px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
]

async function open(data) {
	fetchDict()
	setVisible(true)
	setModel(
		data.map(item => ({
			...item,
			actualAmount: item.status === 1 ? item.tradeAmount - item.deductionAmount : item.actualAmount,
			completionDate: new Date(),
		})),
	)
	setForm({ model: model.value })
	const amount = data.reduce((acc, cur) => {
		acc += cur.tradeAmount || 0
		return acc
	}, 0)
	setAmount(numeral(amount).format('0,0.00'))
}
function convertDate(val) {
	const { statementDate } = val
	return dayjs(statementDate).format('YYYY-MM')
}
function onClose() {
	setVisible(false)
	setForm({})
}
function mapDict(key, value) {
	const e = dicts.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
async function fetchDict() {
	const dicts = await useDict(['trade_bill_type'])
	if (dicts) setDict(dicts)
}

async function onSubmit() {
	await tableRef.value.validate()
	const data = model.value.map(item => ({
		tradeBillId: item.tradeBillId,
		actualAmount: item.actualAmount,
		completionDate: dayjs(item.completionDate).format('YYYY-MM-DD'),
	}))
	const { err } = await fetchData(updateBill, data)
	if (err) return $message.error(err.message)
	$message.success('更新账单状态成功')
	emit('on-refresh')
	onClose()
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
