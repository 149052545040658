<template>
	<el-dialog
		v-model="visible"
		width="700px"
		class="container"
		title="账单减免抵扣"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<el-form :model="formData" :rules="rules" ref="tableRef" style="margin-bottom: 20px">
				<el-table border ref="yiTableRef" height="100%" :columns="columns" :data="formData.model">
					<el-table-column prop="platformChannel" label="平台渠道" width="120px"></el-table-column>
					<el-table-column prop="month" label="账单月份" width="120px">
						<template #default="{ row }">
							<p>{{ convertDate(row) }}</p>
						</template>
					</el-table-column>
					<el-table-column prop="tradeAmount" label="总应收额" width="120px"></el-table-column>
					<el-table-column prop="actualAmount" label="总实收额" width="120px">
						<!-- <template #default="{ row, $index }">
							<el-form-item
								width="120"
								:prop="`model.${$index}.actualAmount`"
								:rules="rules.actualAmount"
							>
								<el-input
									style="margin-top: 20px"
									v-model="row.actualAmount"
									placeholder="请输入总实收额"
								/>
							</el-form-item>
						</template> -->
					</el-table-column>
					<el-table-column
						prop="deductionAmount"
						label="已抵扣金额"
						width="120px"
					></el-table-column>
					<el-table-column prop="carNumber" label="车牌号" width="120px"></el-table-column>
					<el-table-column prop="driverName" label="司机姓名" width="120px"></el-table-column>
				</el-table>
			</el-form>
			<el-form :model="formData" :rules="rules" ref="formRef">
				<el-form-item label="减免抵扣原因" prop="deductReason">
					<el-select v-model="formData.deductReason" placeholder="请选择减免原因">
						<el-option
							v-for="item in status"
							:key="item.code"
							:label="item.name"
							:value="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="减免抵扣金额" prop="deductionAmount">
					<el-input
						v-model="formData.deductionAmount"
						style="width: 190px"
						placeholder="请输入减免抵扣金额"
					/>
				</el-form-item>
				<el-form-item label="减免说明" prop="remark" v-if="formData.deductReason == '其它'">
					<el-input v-model="formData.remark" maxlength="100" placeholder="请输入减免说明" />
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, defineEmits, ref } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { applyDeduction } from '@/api/driver.js'
import { getCompanyPolicy } from '@/api/product.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState([])
const [status, setStatus] = useState([{ name: '其它', code: '其它' }])
const [formData, setForm] = useState({ model: model.value })
const { isLoading, fetchData } = useFetch()

const columns = [
	{
		prop: 'platformChannel',
		label: '平台渠道',
		width: '120px',
	},
	{
		prop: 'month',
		label: '账单月份',
		width: '140px',
	},
	{
		prop: 'tradeAmount',
		label: '总应收额',
		width: '120px',
	},
	{
		prop: 'actualAmount',
		label: '总实收额',
		width: '120px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
]

const formRef = ref(null)
const tableRef = ref(null)

const rules = {
	actualAmount: [
		{ required: true, message: '总实收额不能为空', trigger: 'blur' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '总实收额格式有误', trigger: 'blur' },
	],
	deductReason: [{ required: true, message: '请选择减免原因', trigger: 'blur' }],
	deductionAmount: [
		{ required: true, message: '请输入减免抵扣金额', trigger: 'blur' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '减免抵扣金额格式有误', trigger: 'blur' },
	],
	remark: [{ required: true, message: '请输入减免说明', trigger: 'blur' }],
}

async function open(data) {
	setVisible(true)
	setModel(data)
	fetchDict()
	setForm({ model: model.value })
}
function convertDate(val) {
	const { statementDate } = val
	return dayjs(statementDate).format('YYYY-MM')
}
function onClose() {
	setVisible(false)
	setModel([])
	setForm({ model: [] })
}
async function fetchDict() {
	const { data } = await fetchData(getCompanyPolicy, model.value[0].orderId)
	console.log('data', data)
	if (Array.isArray(data) && data.length) {
		const options = data.map(txt => ({ name: txt, code: txt }))
		setStatus(options.concat({ name: '其它', code: '其它' }))
	}
}

async function onSubmit() {
	await tableRef.value.validate()
	await formRef.value.validate()

	const tradeBillId = model.value.map(item => item.tradeBillId)[0]
	const { deductReason, remark } = formData.value
	const { err } = await fetchData(applyDeduction, {
		tradeBillId,
		actualAmount: model.value[0].actualAmount,
		...formData.value,
		deductReason: deductReason == '其它' ? remark : deductReason,
	})
	if (err) return $message.error(err.message)
	$message.success('申请抵扣成功')
	emit('on-refresh')
	onClose()
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
