<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">账单列表</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/driver-center/rent-bill/update-bill'"
						type="primary"
						@click="onUpdate"
					>
						更新账单状态
					</el-button>
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/driver-center/rent-bill/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter">
					<template #licensePlateNums="{ filterParams }">
						<carNum :filterParams="filterParams" ref="carNumRef" />
					</template>
				</yi-filter>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					showSelection
					autoWidth
					@selection-change="onSelectionChange"
				>
					<template #tradeBillType="{ value }">
						<span>{{ mapDict('trade_bill_type', value) }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('trade_bill_status', value) }}</span>
					</template>
					<template #month="{ row }">
						<span>{{ convertDate(row, 'month') }}</span>
					</template>
					<template #statementDate="{ row }">
						<span>{{ convertDate(row) }}</span>
					</template>
					<template #operation="{ row }">
						<span
							class="click"
							@click="onBillDeduction(row)"
							v-permission="'pc/driver-center/rent-bill/bill-deduction'"
						>
							账单抵扣
						</span>
						<span
							class="click"
							@click="onView(row)"
							v-permission="'pc/driver-center/rent-bill/bill-deduction'"
						>
							日志
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				>
					<span style="font-size: 14px; color: #2e2e2e">
						应收：{{ stat.totalTradeAmount || 0 }}，实收：{{
							stat.totalActualAmount || 0
						}}，抵扣：{{ stat.totalDeductionAmount || 0 }}
					</span>
				</yi-pagination>
			</template>
		</yi-list-layout>
		<driver-form ref="formRef" @on-refresh="fetchList" />
		<bill-deduction ref="billDeductionRef" @on-refresh="fetchList" />
		<el-dialog v-model="visible" width="726px" class="container" title="操作日志">
			<div>
				<yi-table table-height="100%" :columns="operationColumn" :data="operationList"></yi-table>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import carNum from '@/components/YiFilter/carNum'

export default defineComponent({
	name: 'DriverRent',
})
</script>

<script setup name="DriverRent">
import dayjs from 'dayjs'
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { companySelect, channelSelect, maintenanceSelect, operationRecords } from '@/api/vehicle.js'
import { billList, exportBill, billAmountStat } from '@/api/driver.js'
import { handleExport } from '@/utils/util'
import { getStore } from '@/utils/store'

import DriverForm from './form'
import BillDeduction from './billDeduction'

const $message = inject('$message')

const formRef = ref(null)
const carNumRef = ref(null)
const billDeductionRef = ref(null)
const settingRef = ref(setting)
const userInfo = getStore({ name: 'userInfo' })

const operationColumn = [
	{
		prop: 'createByName',
		label: '操作人',
		width: '130px',
	},
	{
		prop: 'createTime',
		label: '操作时间',
		width: '220px',
	},
	{
		prop: 'content',
		label: '操作内容',
		width: '336px',
	},
]

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [operationList, setList] = useState([])
const [selection, setSelection] = useState([])
const [loading, setLoading] = useState(false)
const [visible, setVisible] = useState(false)
const [stat, setStat] = useState({})
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = billList
	fetchStat()
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}
async function fetchStat() {
	const payload = { ...params.value, page: page.value }
	const { data } = await fetchData(billAmountStat, payload)
	console.log('统计', data)
	setStat(data || {})
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function convertDate(val, type) {
	const { statementDate } = val
	if (type === 'month') return dayjs(statementDate).format('YYYY-MM')
	return dayjs(statementDate).format('YYYY-MM-DD')
}

async function onExport() {
	setLoading(true)
	try {
		const res = await exportBill({ ...params.value, page: page.value })
		if (res) handleExport(res, '账单列表')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
function onBillDeduction(row) {
	const invalid = [5, 6, 7].includes(row.status)
	if (invalid) {
		const msg = mapDict('trade_bill_status', row.status)
		return $message.error(msg + '的账单不允许发起减免抵扣')
	}
	billDeductionRef.value.open([row])
}
function onView(row) {
	getLog(row.tradeBillId)
	setVisible(true)
}
async function getLog(id) {
	const { data, err } = await fetchData(operationRecords, {
		logKey: id,
		page: { current: 1, size: 99 },
	})
	if (err) return $message.error(err.message)
	const logData = data.records.map(item => JSON.parse(item.logContent))
	setList(logData)
}
async function fetchSelect() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [chanRes, compRes, maintainRes] = await Promise.all([
		useFilter(() => channelSelect({ isPermission: true })),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
	])
	if (!chanRes.err) {
		const channel = findItem('platformChannelId')
		channel.options = chanRes.data
	}
	if (!compRes.err) {
		const company = findItem('companyId')
		company.options = compRes.data
	}
	if (!maintainRes.err) {
		const target = findItem('driverSupportId')
		target.options = maintainRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}
function onSelectionChange(val) {
	setSelection(val)
}
function onChangeFilter(event) {
	setParams(event)
	fetchList()
}
function onUpdate() {
	if (!selection.value.length) return $message.error('请选择账单')
	const invalid = selection.value.filter(item => [5, 6, 7].includes(item.status))

	if (invalid.length) {
		const msg = mapDict('trade_bill_status', invalid[0].status)
		return $message.error(msg + '的账单不允许更新账单状态')
	}
	formRef.value.open(selection.value)
}

async function fetchDict() {
	const dict = await useDict(['trade_bill_status', 'sex', 'trade_bill_type'])
	if (dict) setDict(dict)
	const status = findItem('statusList')
	status.options = dict.trade_bill_status
	const type = findItem('tradeBillType')
	type.options = dict.trade_bill_type
	console.log(353454, status.options, type.options)
}
function init() {
	fetchDict()
	fetchList()
	fetchSelect()
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		if (carNumRef.value) carNumRef.value.onReset()
		Object.keys(params.value).forEach(key => delete params.value[key])

		params.value.keyword = query.keyword || ''
		params.value.statusList = query.status?.split(',') || null
		params.value.companyId = query?.companyIds?.split(',') || []
		params.value.tradeBillType = query?.tradeBillType || null
		const [start, end] = query?.statementDate?.split(',') || []
		params.value.statementDate =
			(query?.statementDate && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
		const ins = getCurrentInstance()
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	}
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
