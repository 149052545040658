export const columns = [
	{
		prop: 'tradeBillName',
		label: '账单名称',
		width: '180px',
	},
	{
		prop: 'statementPeriod',
		label: '账单周期',
		width: '220px',
	},
	{
		prop: 'tradeAmount',
		label: '总应收额',
		width: '120px',
	},
	{
		prop: 'actualAmount',
		label: '总实收额',
		width: '120px',
	},
	{
		prop: 'deductionAmount',
		label: '抵扣金额',
		width: '120px',
	},
	{
		prop: 'tradeBillType',
		label: '账单类型',
		width: '120',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'carModel',
		label: '车型名称',
		width: '300px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '140px',
	},

	{
		prop: 'carColor',
		label: '颜色',
		// width: '100px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '140px',
	},
	{
		prop: 'platformChannel',
		label: '平台渠道',
		width: '140px',
	},
	{
		prop: 'contractStartDate',
		label: '合同开始日期',
		width: '180px',
	},
	{
		prop: 'statementMonth',
		label: '账单月',
		width: '140px',
	},
	{
		prop: 'status',
		label: '账单状态',
		width: '140px',
	},
	{
		prop: 'completionDate',
		label: '完成支付时间',
		width: '140px',
	},
	{
		prop: 'statementDate',
		label: '账单日期',
		width: '160px',
	},
	{
		prop: 'tradeAmount',
		label: '月租（元）',
		width: '140px',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '300px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '250px',
	},
	{
		prop: 'tradeBillNo',
		label: '账单ID',
		width: '270px',
	},
	{
		prop: 'tradeRemark',
		label: '补充说明',
		width: '180px',
	},
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择车牌号',
			label: '车牌号',
			attr: 'licensePlateNums',
			isCustom: true,
			options: [],
		},
		{
			placeholder: '请选择账单状态',
			label: '账单状态',
			attr: 'statusList',
			type: 'multi-select',
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择司服伙伴',
			label: '司服伙伴',
			attr: 'driverSupportId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择平台渠道',
			label: '平台渠道',
			attr: 'platformChannelId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择账单开始时间',
			label: '账单开始时间',
			attr: 'statementDate',
			type: 'date',
		},
		{
			placeholder: '请选择账单类型',
			label: '账单类型',
			attr: 'tradeBillType',
			type: 'select',
			options: [],
		},

		{
			placeholder: '请输入账单名称',
			label: '账单名称',
			attr: 'tradeBillNameWord',
			type: 'search',
		},
		{
			placeholder: '请选择账单创建时间',
			label: '账单创建时间',
			attr: 'createTimeList',
			type: 'date',
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID' },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机姓名' },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号' },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证号' },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择性别' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证地址' },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址' },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		component: 'el-select',
		options: [],
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		attrs: { placeholder: '请输入联系人手机' },
	},
	{
		prop: 'drivingLicenseDate',
		label: '驾驶证日期',
		custom: true,
	},
]

export const uploadForm = [
	{
		prop: 'idCardPic',
		required: true,
		label: '身份证',
		custom: true,
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
